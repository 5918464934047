import {
  Box,
  Button,
  Container,
  Flex,
  Heading,
  Spacer,
  Text,
  useColorMode,
} from "@chakra-ui/react"
import React, { useEffect, useState } from "react"
import BannerBlepharo from "../components/choc/BannerBlepharo"
import BannerHyaloron from "../components/choc/BannerHyaloron"
import LogoHeadlineTeaser from "../components/choc/LogoHeadlineTeaser"
import LogoHeadlineTeaserVeo from "../components/choc/LogoHeadlineTeaserVEO"
import { Modal2 } from "../components/choc/ModalDVT"
import QuadTeaser from "../components/choc/QuadTeaser"
import ImgTextModule from "../components/ImgTextModule"
import LayoutA from "../components/layout/Layout"
import FadeInAnimation from "../DefinitionComponents/Animation-Components/FadeInAnimation"
import ImgMaskReveal from "../DefinitionComponents/Animation-Components/ImgMaskReveal"
import MaskSlidInAnimation from "../DefinitionComponents/Animation-Components/MaskSlideInAnimation"
import RouterLink from "../DefinitionComponents/Utility/Routing/RouterLink"
import ThreeColumnGridBoxesDocs from "../components/ThreeColumnGridBoxesDocs"
import ThreeColumnGridBoxeswaswirtun from "../components/ThreeColumnGridBoxeswaswirtun"
const Dental = () => {
  let { toggleColorMode, ColorMode, setColorMode } = useColorMode()
  useEffect(() => {
    setColorMode(colormodeValue)
  })
  const [isToggledDVT, setToggleDVT] = useState(null)
  const [viewportHeight, setViewportHeight] = useState(0)
  const [pageHeight, setPageHeight] = useState(0)
  useEffect(() => {
    setViewportHeight(window.innerHeight)
    setPageHeight(document.body.clientHeight)
    // console.log('VIEWPORTHEIGHT: ' + window.innerHeight)
    // console.log('PAGEHEIGHT: ' + document.body.clientHeight)
  }, [])
  const [colormodeValue, changeColorModeValue] = useState("light")
  return (
    <LayoutA
      title={"JadeKlinik | VeoMed"}
      description={
        "Mund- Kiefer- und Gesichtschirurgie. Der Patient im Mittelpunkt. "
      }
    >
      {" "}
      <Container variant="layoutContainer" my={24} maxW={"1720px"}>
        <ImgTextModule
          src={"docs.mp4"}
          delay={0.3}
          duration={0.5}
          threshold={0.3}
          initialY={10}
          initialX={10}
          video={true}
          h={{ base: "96vh", md: "66vh", lg: "65vh", xl: "65vh" }}
          px={{ base: 0, md: 12, lg: 4, xl: 0 }}
          mediaOpacity={0.75}
          bgColor="black"
        >
          <Box p={{ base: 0, lg: "8" }}>
            <FadeInAnimation
              threshold={0.4}
              delay={0.4}
              duration={0.7}
              initialX={0}
              initialY={-10}
            >
              <Heading
                color="brand.tan.ulight"
                fontWeight="bold"
                fontSize={{ base: "5xl", lg: "6xl" }}
              >
                Wilkommen in der JadeKlinik
              </Heading>
            </FadeInAnimation>

            <Flex mt={0} justifyContent="start" flexWrap={"wrap"}>
              <MaskSlidInAnimation threshold={1} duration={1}>
                <Heading
                  mt={2}
                  w={{ base: "100%", md: "77%", lg: "50%" }}
                  color={"white"}
                  fontSize={{ lg: "2xl", md: "xl", base: "lg" }}
                  fontWeight="normal"
                  letterSpacing="tighter"
                >
                  Informieren Sie sich hier über hochmoderne, ganzheitliche
                  Qualitätschirurgie, bei der Sie als Patient im Mittelpunkt
                  stehen. Wir möchten, dass Sie sich bei uns fachlich und
                  menschlich bestens aufgehoben fühlen.
                </Heading>
              </MaskSlidInAnimation>

              <FadeInAnimation
                threshold={0.4}
                delay={0.5}
                duration={1.4}
                initialX={0}
                initialY={10}
              >
                <RouterLink
                  isExternal={true}
                  href="https://web.online-tis.de/start/M24437Yb6M"
                >
                  <Button mt={4} variant="ghost" color="white">
                    Termine online
                  </Button>
                </RouterLink>
              </FadeInAnimation>

              <FadeInAnimation
                threshold={0.4}
                delay={0.74}
                duration={1.4}
                initialX={0}
                initialY={10}
              >
                <RouterLink isExternal={false} link={"/kontakt/"}>
                  <Button
                    ml={4}
                    mt={4}
                    variant="solid"
                    color="brand.violet.primary"
                    _hover={{
                      backgroundColor: "brand.violet.primary",
                      color: "white",
                    }}
                  >
                    Kontakt
                  </Button>
                </RouterLink>
              </FadeInAnimation>
            </Flex>
          </Box>
        </ImgTextModule>
      </Container>
      {/* Logo Module */}
      <LogoHeadlineTeaserVeo
        py={24}
        headline="Spezialisten und Experten"
        subheadline="Unsere Ärzte und Fachärzte, die sich leidenschaftlich für Ihre Gesundheit einsetzen."
      />
      <Container variant="layoutContainer">
        <ImgTextModule
          src={"short-master005.mp4"}
          mt={4}
          px={{ base: 4, md: 12, lg: 4, xl: 0 }}
          delay={0.3}
          duration={0.5}
          threshold={0.3}
          initialY={10}
          initialX={10}
          video={true}
          h={{ base: "80vh", md: "50vh", lg: "50vh", xl: "50vh" }}
          mediaOpacity={0.7}
          bgColor="brand.gray.vdark"
        >
          <Box p={{ base: 0, lg: "8" }}>
            <FadeInAnimation
              threshold={0.4}
              delay={0.4}
              duration={0.7}
              initialX={0}
              initialY={-10}
            >
              <Text
                color="white"
                fontWeight="normal"
                fontSize={{ base: "md", lg: "md" }}
              >
                Der Patient im Mittelpunkt.
              </Text>
            </FadeInAnimation>
            <FadeInAnimation
              threshold={0.4}
              delay={0.4}
              duration={0.7}
              initialX={0}
              initialY={-10}
            >
              <Text
                color="white"
                fontWeight="bold"
                fontSize={{ base: "5xl", lg: "6xl" }}
              >
                Dr. Jann Voltmann
              </Text>
            </FadeInAnimation>

            <Flex
              mt={0}
              flexWrap="wrap"
              flexDirection={{ base: "column", md: "row" }}
              justifyContent="start"
            >
              <Box>
                <MaskSlidInAnimation threshold={1} duration={1}>
                  <Heading
                    maxW={{ base: "100%", md: "50%" }}
                    mr={32}
                    mt={2}
                    color={"white"}
                    fontSize={{ lg: "2xl", md: "xl", base: "xl" }}
                    fontWeight="normal"
                    w={{ base: "100%", md: "50%" }}
                    letterSpacing="tight"
                  >
                    Mund-, Kiefer- und Gesichtschirurg.
                  </Heading>
                </MaskSlidInAnimation>{" "}
                {/* <FadeInAnimation
                  threshold={0.4}
                  delay={1}
                  duration={0.7}
                  initialX={0}
                  initialY={10}
                >
                  <Button
                    mt={4}
                    onClick={() => setToggleDVT(true)}
                    variant="ghost"
                    color="white"
                  >
                    Mehr erfahren
                  </Button>
                </FadeInAnimation> */}
              </Box>

              <Spacer />
            </Flex>
          </Box>
        </ImgTextModule>
      </Container>
      <ThreeColumnGridBoxesDocs />
      <LogoHeadlineTeaserVeo
        py={24}
        headline="Von klein bis GROSS"
        subheadline="Unsere Leistungen umfassen das ganze Spektrum Mund-, Kiefer- und Gesichtschirurgischer Eingriffe."
      />
      <Container variant="layoutContainer" minH="56vh">
        <ImgTextModule
          src={"223970.mp4"}
          mt={4}
          px={{ base: 0, md: 12, lg: 4, xl: 0 }}
          delay={0.3}
          duration={0.5}
          threshold={0.3}
          initialY={10}
          initialX={10}
          video={true}
          h="70vh"
          mediaOpacity={0.7}
          bgColor="brand.gray.vdark"
        >
          <Box p={{ base: 0, lg: "8" }}>
            <FadeInAnimation
              threshold={0.4}
              delay={0.4}
              duration={0.7}
              initialX={0}
              initialY={-10}
            >
              <Text
                color="white"
                fontWeight="normal"
                fontSize={{ base: "md", lg: "lg" }}
              >
                Modernste Technik. Menschliche Zuwendung
              </Text>
            </FadeInAnimation>
            <FadeInAnimation
              threshold={0.4}
              delay={0.4}
              duration={0.7}
              initialX={0}
              initialY={-10}
            >
              <Heading
                color="white"
                fontWeight="normal"
                fontSize={{ base: "3xl", lg: "6xl" }}
              >
                Menschen mit Charakter.
              </Heading>
            </FadeInAnimation>

            <Flex mt={0} justifyContent="start" flexWrap={"wrap"}>
              <Box pr={12}>
                <MaskSlidInAnimation threshold={1} duration={1}>
                  <Heading
                    mr={32}
                    pr={8}
                    color={"white"}
                    fontSize={{ lg: "xl", md: "lg", base: "md" }}
                    fontWeight="normal"
                    w={"100%"}
                    lineHeight={"base"}
                    letterSpacing="tighter"
                  >
                    Unsere Leidenschaft für unsere Arbeit geht über das gewohnte
                    Maß hinaus.
                  </Heading>
                </MaskSlidInAnimation>

                <FadeInAnimation
                  threshold={0.4}
                  delay={0.5}
                  duration={1.4}
                  initialX={0}
                  initialY={10}
                >
                  <Text
                    pr={8}
                    color={"white"}
                    fontSize={{ lg: "lg", md: "md", base: "md" }}
                    fontWeight="normal"
                    w={"100%"}
                    lineHeight={"base"}
                    letterSpacing="tighter"
                  >
                    Wir identifizieren uns mit unserer Berufung. Und die gilt
                    Ihnen.
                  </Text>
                </FadeInAnimation>
              </Box>
              <Flex>
                <FadeInAnimation
                  threshold={0.4}
                  delay={0.5}
                  duration={1.4}
                  initialX={0}
                  initialY={10}
                >
                  <RouterLink
                    isExternal={true}
                    href="https://web.online-tis.de/start/M24437Yb6M"
                  >
                    <Button mt={4} variant="ghost" color="white">
                      Termine online
                    </Button>
                  </RouterLink>
                </FadeInAnimation>

                <FadeInAnimation
                  threshold={0.4}
                  delay={0.74}
                  duration={1.4}
                  initialX={0}
                  initialY={10}
                >
                  <RouterLink isExternal={false} link={"/kontakt/"}>
                    <Button
                      ml={4}
                      mt={4}
                      variant="solid"
                      color="brand.violet.primary"
                      _hover={{
                        backgroundColor: "brand.violet.primary",
                        color: "white",
                      }}
                    >
                      Kontakt
                    </Button>
                  </RouterLink>
                </FadeInAnimation>
              </Flex>
            </Flex>
          </Box>
        </ImgTextModule>
      </Container>
      <ThreeColumnGridBoxeswaswirtun />
      <Container variant="layoutContainer" minH="86vh">
        <ImgTextModule
          src={
            "381150_Doctor_Technician_Imaging_Screen_By_Anna_Tolipova_Artlist_HD.mp4"
          }
          mt={4}
          px={{ base: 0, md: 12, lg: 4, xl: 0 }}
          delay={0.3}
          duration={0.5}
          threshold={0.3}
          initialY={10}
          initialX={10}
          video={true}
          h="76vh"
          mediaOpacity={0.7}
          bgColor="brand.gray.vdark"
        >
          <Box p={{ base: 4, lg: "8" }}>
            <FadeInAnimation
              threshold={0.4}
              delay={0.4}
              duration={0.7}
              initialX={0}
              initialY={-10}
            >
              <Text
                color="white"
                fontWeight="normal"
                fontSize={{ base: "lg", lg: "xl" }}
              >
                Modernste Verfahren, die wir nutzen.
              </Text>
            </FadeInAnimation>
            <FadeInAnimation
              threshold={0.4}
              delay={0.4}
              duration={0.7}
              initialX={0}
              initialY={-10}
            >
              <Text
                color="white"
                fontWeight="bold"
                fontSize={{ base: "3xl", lg: "6xl" }}
              >
                DVT Tomografie
              </Text>
            </FadeInAnimation>
            <Flex mt={0} justifyContent="start" flexWrap={"wrap"}>
              <MaskSlidInAnimation threshold={1} duration={1}>
                <Heading
                  maxW={{ base: "100%", md: "50%" }}
                  mr={{ base: 0, lg: 32 }}
                  mt={2}
                  color={"white"}
                  fontSize={{ lg: "md", md: "md", base: "sm" }}
                  fontWeight="normal"
                  w={{ base: "100%", md: "50%" }}
                  letterSpacing="tight"
                >
                  In der Facharztpraxis für Mund-, Kiefer- und Gesichtschirurgie
                  Wilhelmshaven steht Ihnen mit der digitalen Volumentomographie
                  (DVT) eine hochpräzise 3D-Röntgendiagnostik zur Verfügung.
                  Unser DVT-Gerät der neuesten Generation bietet für die Zahn-,
                  Mund- und Kieferheilkunde umfassende, dreidimensionale
                  Untersuchungsmöglichkeiten
                  <br />
                  <br /> Es ermöglicht einen virtuellen Rundgang durch den
                  Kiefer und bei Bedarf durch den gesamten Gesichtsschädel.
                  Dabei ist die Strahlenbelastung für den Patienten im Vergleich
                  zur herkömmlichen Computertomographie (CT) um ein Vielfaches
                  geringer.
                </Heading>
              </MaskSlidInAnimation>
              <FadeInAnimation
                threshold={0.4}
                delay={1}
                duration={0.7}
                initialX={0}
                initialY={10}
              >
                <Button
                  mt={4}
                  onClick={() => setToggleDVT(true)}
                  variant="ghost"
                  color="white"
                >
                  Was ist DVT ?
                </Button>
              </FadeInAnimation>
              <Spacer />
            </Flex>
          </Box>
        </ImgTextModule>
      </Container>
      <Container variant="layoutContainer" minH="86vh">
        <ImgTextModule
          src={"short-master005.mp4"}
          mt={4}
          px={{ base: 0, md: 12, lg: 4, xl: 0 }}
          delay={0.3}
          duration={0.5}
          threshold={0.3}
          initialY={10}
          initialX={10}
          video={true}
          h="76vh"
          mediaOpacity={0.7}
          bgColor="brand.gray.vdark"
        >
          <Box p={{ base: 4, lg: "8" }}>
            <FadeInAnimation
              threshold={0.4}
              delay={0.4}
              duration={0.7}
              initialX={0}
              initialY={-10}
            >
              <Text
                color="white"
                fontWeight="normal"
                fontSize={{ base: "lg", lg: "xl" }}
              >
                Kopf-Hals-Tumoren
              </Text>
            </FadeInAnimation>
            <FadeInAnimation
              threshold={0.4}
              delay={0.4}
              duration={0.7}
              initialX={0}
              initialY={-10}
            >
              <Text
                color="white"
                fontWeight="bold"
                fontSize={{ base: "2xl", lg: "4xl" }}
              >
                Behandlung von Patienten mit
              </Text>
            </FadeInAnimation>
            <FadeInAnimation
              threshold={0.4}
              delay={0.4}
              duration={0.7}
              initialX={0}
              initialY={-10}
            >
              <Text
                color="white"
                fontWeight="bold"
                fontSize={{ base: "3xl", lg: "6xl" }}
              >
                Kopf-Hals-Tumoren
              </Text>
            </FadeInAnimation>
            <Flex mt={0} justifyContent="start" flexWrap={"wrap"}>
              <MaskSlidInAnimation threshold={1} duration={1}>
                <Heading
                  maxW={{ base: "100%", md: "50%" }}
                  mr={{ base: 0, lg: 32 }}
                  mt={2}
                  color={"white"}
                  fontSize={{ lg: "md", md: "md", base: "sm" }}
                  fontWeight="normal"
                  w={{ base: "100%", md: "50%" }}
                  letterSpacing="tight"
                >
                  Mund-, Kiefer- und Gesichtschirurg Dr. Jann Voltmann M.Sc. hat
                  langjährige Erfahrung in der Therapie und Nachsorge von
                  Patienten mit Kopf-Hals-Tumoren. Der Facharzt für
                  MKG-Chirurgie und Fachzahnarzt für Oralchirurgie hat mehr als
                  100 erfolgreiche mikrochirurgische und gestielte
                  Lappentransfers zur Rekonstruktion von Gewebedefekten im
                  Kopf-Halsbereich vorgenommen und verfügt somit über eine
                  umfassende Expertise auf diesem Gebiet.
                </Heading>
              </MaskSlidInAnimation>
              <FadeInAnimation
                threshold={0.4}
                delay={1}
                duration={0.7}
                initialX={0}
                initialY={10}
              >
                <Button
                  mt={4}
                  onClick={() => setToggleDVT(true)}
                  variant="ghostsmall"
                  color="white"
                >
                  Kopf-Hals Tumore
                </Button>
              </FadeInAnimation>
              <Spacer />
            </Flex>
          </Box>
        </ImgTextModule>
      </Container>
      <Modal2 isToggled={isToggledDVT} setToggle={setToggleDVT}></Modal2>
      <LogoHeadlineTeaser
        my={24}
        headline="Ästhetik mit High-Tech"
        subheadline="Und Verantwortung"
      />
      <BannerHyaloron mb={8} />
      <BannerBlepharo mb={8} />
      <QuadTeaser />
    </LayoutA>
  )
}

export default Dental
